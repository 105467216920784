import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './publication.css';
import { Scrollbars } from 'rc-scrollbars';
import { Typography } from '@mui/material';

// Data structure for publications, categorized
const publicationsData = {
    fullPapers: [
        {
            title: "Examining Human Perception of Generative Content Replacement in Image Privacy Protection",
            authors: "Anran Xu, Shitao Fang, Huan Yang, Simo Hosio, Koji Yatani",
            conference: "CHI Conference on Human Factors in Computing Systems (CHI '24)",
            pdfLink: "https://s3.ap-northeast-1.amazonaws.com/anranxu.com/pdf/CHI2024.pdf",
            imageSrc: './img/CHI2024.png',
            extraLinks: [
                { label: "Github", url: "https://github.com/AnranXu/Generative-Content-Replacement.git" },
                { label: "PDF", url: "https://doi.org/10.1145/3613904.3642103" }
            ],
            awards: [] // Empty awards array
        },
        {
            title: "DIPA2: An Image Dataset with Cross-cultural Privacy Perception Annotations",
            authors: "Anran Xu, Zhongyi Zhou, Kakure Miyazaki, Ryo Yoshikawa, Simo Hosio, Koji Yatani",
            conference: "Proc. ACM Interact. Mob. Wearable Ubiquitous Technol. (IMWUT/Ubicomp '24)",
            pdfLink: "https://dl.acm.org/doi/10.1145/3631439",
            imageSrc: './img/UBICOMP24.png',
            extraLinks: [
                { label: "Homepage", url: "https://anranxu.github.io/DIPA2_VIS/" },
                { label: "PDF", url: "https://dl.acm.org/doi/10.1145/3631439" }
            ],
            awards: ["IMWUT Vol. 7 Distinguished Paper Award"] // Added awards field
        },
        {
            title: "SyncUp: Vision-based Practice Support for Synchronized Dancing",
            authors: "Zhongyi Zhou, Anran Xu, Koji Yatani",
            conference: "Proc. ACM Interact. Mob. Wearable Ubiquitous Technol. (IMWUT/Ubicomp '21)",
            awards: ["PACM IMWUT Distinguished Paper Award", "Best Presentation Award"],
            pdfLink: "https://doi.org/10.1145/3478120",
            imageSrc: './img/imwut21_syncup.jpg',
            extraLinks: [
                { label: "PDF", url: "https://doi.org/10.1145/3478120" }
            ],
            awards: ['IMWUT Vol. 5 Distinguished Paper Award'] 
        },
        {
            title: "Off-Road Drivable Area Extraction Using 3D LiDAR Data",
            authors: "Biao Gao, Anran Xu, Yancheng Pan, Xijun Zhao, Wen Yao, Huijing Zhao",
            conference: "30th IEEE Intelligent Vehicles Symposium (IV '19)",
            pdfLink: "https://arxiv.org/pdf/2003.04780.pdf",
            imageSrc: './img/IV19_offroad.PNG',
            extraLinks: [
                { label: "PDF", url: "https://arxiv.org/pdf/2003.04780.pdf" }
            ],
            awards: [] // Empty awards array
        }
    ],
    shortPapers: [
        {
            title: "DIPA: An Image Dataset with Cross-cultural Privacy Concern Annotations",
            authors: "Anran Xu, Zhongyi Zhou, Kakure Miyazaki, Ryo Yoshikawa, Simo Hosio, Koji Yatani",
            conference: "IUI 2023 Open Science track",
            pdfLink: "https://doi.org/10.1145/3581754.3584176",
            imageSrc: './img/IUI23.png',
            extraLinks: [
                { label: "Homepage", url: "https://anranxu.github.io/DIPA_visualization/" },
                { label: "PDF", url: "https://doi.org/10.1145/3581754.3584176" }
            ],
            awards: [] // Empty awards array
        },
        {
            title: "Vision-based Scene Analysis toward Dangerous Cycling Behavior Detection Using Smartphones",
            authors: "Hirotaka Hayashi, Anran Xu, Zhongyi Zhou, Koji Yatani",
            conference: "UbiComp-ISWC '21 demo",
            pdfLink: "https://dl.acm.org/doi/10.1145/3460418.3479300",
            imageSrc: './img/ubicomp21demo.jpg',
            extraLinks: [
                { label: "PDF", url: "https://dl.acm.org/doi/10.1145/3460418.3479300" }
            ],
            awards: [] // Empty awards array
        }
    ],
    japanesePapers: [
        {
            title: "シームレスでカスタマイズ可能な写真プライバシー保護加工を対話的に行うインタフェース / An Interactive Application That Realizes Seamless and Customizable Photo Privacy Protection",
            authors: "徐 安然 (Anran Xu), 風澤 宥吾 (Yugo Fusawa), 矢谷 浩司 (Koji Yatani)",
            conference: "コンピュータセキュリティシンポジウム2024 / Computer Security Symposium (CSS) 2024",
            pdfLink: "https://conferenceservice.jp/registration/css2024/mypage/proceedings/IPSJCSS-2024070.pdf",
            imageSrc: './img/CSS.png',
            extraLinks: [
                { label: "PDF", url: "https://conferenceservice.jp/registration/css2024/mypage/proceedings/IPSJCSS-2024070.pdf" }
            ],
            awards: [] // Empty awards array
        },
        {
            title: "写真のプライバシー保護加工を対話的に行うインタフェース / HideTight: An Interactive Interface For Photo Privacy Protection Processing",
            authors: "徐 安然 (Anran Xu), 周 中一 (Zhongyi Zhou), 吉川 諒 (Ryo Yoshikawa), 矢谷 浩司 (Koji Yatani)",
            conference: "第74回ユビキタスコンピューティングシステム研究発表会 / The 74th Annual Conference on Ubiquitous Computing Systems (UBI)",
            pdfLink: "https://iis-lab.org/wp-content/uploads/2022/06/IPSJUBI-202206.pdf",
            imageSrc: './img/UBI.png',
            extraLinks: [
                { label: "Demo", url: "https://anranxu.com/hidetight/instruction/" },
                { label: "PDF", url: "https://iis-lab.org/wp-content/uploads/2022/06/IPSJUBI-202206.pdf" }
            ],
            awards: [] // Empty awards array
        }
    ]
};

function formatAuthors(authors) {
    const authorList = authors.split(", ");
    return authorList.map((author, index) => (
        author.includes("Anran Xu") ?
            <strong key={index}>{author}</strong> : // Make the entire segment bold if "Anran Xu" is included
            <span key={index}>{author}</span>        // Otherwise, render normally
    )).reduce((prev, curr, index) => [prev, ', ', curr]); // This ensures commas are placed correctly between author names
}

// Publication Entry Component
function PublicationEntry({ publication }) {
    const resizeImage = (e) => {
        const img = e.target;
        const container = img.parentElement;

        const containerAspectRatio = container.offsetWidth / container.offsetHeight;
        const imageAspectRatio = img.naturalWidth / img.naturalHeight;

        if (imageAspectRatio > containerAspectRatio) {
            img.style.width = '100%';
            img.style.height = 'auto';
        } else {
            img.style.width = 'auto';
            img.style.height = '100%';
        }
    };
    return (
        <Container fluid>
            <Row>
                <Col xl={5}>
                    <a href={publication.pdfLink} target="_blank" rel="noopener noreferrer">
                        <div className='pic-container'>
                            <img
                                src={require(`${publication.imageSrc}`)}
                                className='pic'
                                alt="Publication"
                                onLoad={resizeImage}
                            />
                        </div>
                    </a>
                </Col>
                <Col xl={1}></Col>
                <Col xl={6}>
                    <h4><strong>{publication.title}</strong></h4>
                    <h5>{formatAuthors(publication.authors)}</h5>
                    <h5>{publication.conference}</h5>
                    {/* Display awards if they exist */}
                    {publication.awards && publication.awards.length > 0 && (
                        <h5><strong>{publication.awards.join(', ')}</strong></h5>
                    )}
                    {/* Render extraLinks */}
                    <h5>
                        {publication.extraLinks.map((link, index) => (
                            <span key={index}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a>
                                {index < publication.extraLinks.length - 1 ? <span>&nbsp;&nbsp;</span> : null}
                            </span>
                        ))}
                    </h5>
                </Col>
            </Row>
            <div style={{ height: 50 }} />
        </Container>
    );
}

// Main Publication Component
class Publication extends Component {
    renderSection = (papers, sectionTitle) => (
        <div>
            <Typography variant="h5" gutterBottom>{sectionTitle}</Typography>
            {papers.map((paper, index) => <PublicationEntry key={index} publication={paper} />)}
        </div>
    );

    render() {
        return (
            <div className='publicationMain'>
                {this.props.show ? 
                    window.innerWidth > this.props.xlBoundary ?
                    <Scrollbars style={{ height: document.documentElement.clientHeight, marginTop: '30px', marginLeft: '50px'}}>
                        <Typography variant="h4" gutterBottom>Publications</Typography>
                        {this.renderSection(publicationsData.fullPapers, "Full Papers")}
                        {this.renderSection(publicationsData.shortPapers, "Workshop Papers (posters, demos, etc.)")}
                        {this.renderSection(publicationsData.japanesePapers, "Japanese Papers (Non-Peer-Reviewed)")}
                    </Scrollbars>:
                    <div style={{marginTop: '30px'}}>
                        <Typography variant="h4" gutterBottom>Publications</Typography>
                        {this.renderSection(publicationsData.fullPapers, "Full Papers")}
                        {this.renderSection(publicationsData.shortPapers, "Workshop Papers (posters, demos, etc.)")}
                        {this.renderSection(publicationsData.japanesePapers, "Japanese Papers (Non-Peer-Reviewed)")}
                    </div>
                : null}
            </div>
        );
    }
}

export default Publication;
